<template>
  <div class="car-not-found">
    <download-excel :data="userSubscriptionList">
      <v-btn outlined>
        Download Data
      </v-btn>
    </download-excel>

    <v-data-table
      :headers="headers"
      :items="userSubscriptionList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.promoCode }}</td>
          <td>{{ row.item.username }}</td>
          <td>{{ row.item.email }}</td>
          <td>{{ convertDate(row.item.subscribedDate) }}</td>
          <td>{{ convertDate(row.item.subscribedEndDate) }}</td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10000"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
import JsonExcel from "vue-json-excel";

import getAxios from "../../../services/axios-get";

Vue.use(VueAxios, axios, moment);
Vue.component("downloadExcel", JsonExcel);

export default {
  data() {
    return {
      userSubscriptionList: [],
      pageCount: 0,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Promo Code", value: "promoCode" },
        { text: "Username", value: "username" },
        { text: "Email", value: "email" },
        { text: "Subscribed Date", value: "subscribedDate" },
        { text: "Subscribed End Date", value: "subscribedEndDate" },
        { text: "", value: "" },
      ],
      status: "",
    };
  },
  computed: {},
  mounted() {
    this.fetchCredentials();
    this.fetchUserSubscriptionList();
  },
  methods: {
    convertDate(date) {
      var parsedDate = moment
        .utc(date)
        .local()
        .format("dddd, MMMM Do YYYY, h:mm:ss A");
      return parsedDate;
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchUserSubscriptionList();
    },
    fetchUserSubscriptionList() {
      // ${process.env.VUE_APP_SERVER_URL}/
      getAxios(`${process.env.VUE_APP_SERVER_URL}/magazineSystem/subscribers`)
        .then(res => {
          if (res.data) {
            this.userSubscriptionList = res.data.data;
            // console.log(this.userSubscriptionList);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
  },
};
</script>
